.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;

  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    /* padding: 109px 0 82px 36px; */
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background: var(--marketplaceColorLighter);
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 86px 0 82px calc((100% - 1056px) / 2); */
    padding-left: 60px;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;

  color: var(--marketplaceColorLight);
  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.tab a.cropsie_tab_link_selected {
  color: var(--marketplaceColorDark);
  background-image: none;
}

.tab .cropsie_tab_link {
  @apply --marketplaceTabNavFontStyles;

  color: var(--marketplaceColorLight);
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.3px;

  padding-bottom: 10px;
  padding-top: 10px;

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }
}
