@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1215px) {
    width: calc(50% - 12px);
  }
}

.fieldFullWidth {
  width: 100%;
  margin-top: 24px;
}

.fieldShort {
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1215px) {
    flex: 0 0 125px;
    width: auto;
  }
}

.fieldLong {
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1215px) {
    flex: 1 0 auto;
    width: auto;

    &:first-child {
      padding-right: 24px;
    }

    &:last-child {
      padding-left: 24px;
    }
  }
}

.formRow input,
.fieldFullWidth select {
  @apply --cropsieSettingsPageFormInput;
}
