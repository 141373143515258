@import '../../styles/customMediaQueries.css';

.root {
  border: 1px solid #cdece1;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
  transition: var(--transitionStyle);
}

.header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 10px 24px;

  @media (--viewportLarge) {
    padding: 10px 33px 10px 24px;
  }
}

.title {
  color: var(--marketplaceColorLight);
  font-size: 17px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 1.4;
  margin: 0;
  padding-right: 10px;

  @media (--viewportLarge) {
    font-size: 23px;
    line-height: 32px;
    margin-top: 2px;
  }
}

.content {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightRegular);
  line-height: 1.77;
  padding: 15px 24px 20px;

  @media (--viewportLarge) {
    font-size: 18px;
  }

  & a {
    color: var(--marketplaceColorLight);
  }
}

.arrow {
  background: url('data:image/svg+xml;utf8,<svg width="21" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 11 0 1.47 1.673 0 10.5 7.977 19.327 0 21 1.512z" fill="%232F4F38" fill-rule="nonzero"/></svg>')
    no-repeat center;
  display: block;
  flex-shrink: 0;
  height: 11px;
  width: 21px;
}

.isOpen {
  & .arrow {
    transform: rotate(180deg);
  }
}
