@import '../../../styles/propertySets.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.searchFields {
  flex-grow: 1;
  height: 100%;
  padding: 0 15px;
  position: relative;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    margin-right: 14px;
    padding: 0 24px;
    background-color: var(--matterColorLight);
    border-radius: 24px;
  }
}

.locationSearchWrapper {
  margin-top: 10px;

  @media (--viewportLarge) {
    margin-top: 0;
    flex-basis: 50%;
  }
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Layout */
  margin: 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  height: var(--topbarSearchHeight);
  border: none;

  display: flex;
  width: 24px;
  align-self: stretch;
}

.desktopLocationIcon {
  display: none;
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  height: var(--topbarSearchHeight);
  line-height: unset;
  border: none;
  padding-top: 2px;
  padding-bottom: 0;
  padding-left: 18px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    color: var(--marketplaceColor);
    opacity: 1;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--marketplaceColorDark);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }

  @media (--viewportMedium) and (max-width: 1023px) {
    font-size: 13px;
    padding: 0 8px;
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  background-color: #ffffff;

  & :global(.location_predictions) li {
    color: #000000;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    padding: 10px 26px;

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &.highlighted {
      border-left: 6px solid var(--matterColorDark);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: var(--matterColorDark);
    }

    & svg {
      position: absolute;
      right: 26px;
    }
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 35px;
  left: 0;
  background-color: #f8fffd;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 8px;

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
  & :global(.location_predictions) li {
    color: #000000;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    padding: 10px 26px;

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &.highlighted {
      border-left: 6px solid var(--matterColorDark);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: var(--matterColorDark);
    }

    & svg {
      position: absolute;
      right: 26px;
    }
  }
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: block;
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 50%;
  }
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 0px;
  width: 55px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: var(--marketplaceColorLight);
  padding-left: 15px;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--matterColor);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: #ffffff;
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.info {
  display: flex;
  margin-top: 10px;
}

.infoIcon {
  flex-basis: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoText {
  flex-basis: 90%;
}

.searchMobileButton {
  background-color: var(--marketplaceColorLight);
  border-color: var(--marketplaceColorLight);
  margin-top: 20px;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
  }
}
