@import '../../styles/propertySets.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 16px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  line-height: 1;
  margin: 0 24px 8px;

  @media (--viewportMedium) {
    font-size: 32px;
    margin: 0 0 25px;
  }
}

.author {
  display: flex;
  flex-direction: row;
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin: 24px 24px 32px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
    color: var(--matterColor);
  }
}

.providerAvatar {
  margin-right: 10px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 24px;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 4px;

  @media (--viewportLarge) {
    font-size: 24px;
    margin-bottom: 4px;
  }
}

.orderUnit {
  color: #698571;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 25px;
}

.orderUnitMobile {
  composes: orderUnit;
  margin: 0 24px 16px;

  @media (--viewportMedium) {
    display: none;
  }
}

.orderHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceButtonStylesChevronWhite;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.shippingOptions {
  @media (--viewportMedium) {
    display: flex;
  }
}

.shippingOption {
  border-radius: 15px;
  color: #7b9481;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 24px;
  height: 30px;
  padding: 0 24px;

  & svg {
    margin-right: 12px;
  }

  @media (--viewportMedium) {
    background-color: var(--marketplaceColorLightest);
    margin-right: 12px;
    padding: 0 16px;
  }

  @media (--viewportLarge) {
    background-color: var(--matterColorLight);
  }
}

.shippingOptionIcon {
  & path {
    fill: #7b9481;
  }
}

.ownListingMessage {
  padding: 0 24px 32px;

  @media (--viewportMedium) {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 0 60px;
  }

  @media (--viewportLarge) {
    margin: 0;
    margin-top: 20px;
    max-width: 280px;
    padding: 0;
  }

  & p {
    color: var(--matterColor);
    background-color: var(--marketplaceColorMoreLight);

    font-size: 18px;
    font-weight: 600;
    text-align: left;
    line-height: 27px;
    opacity: 0.9;
    letter-spacing: 0.3px;

    margin: 0 0 18px;
    padding: 10px 20px;
    border-radius: 10px;
  }
}

.ownListingMessageBold {
  font-weight: normal;
}

.ownListingButton {
  @apply --marketplaceButtonStylesSecondaryGreen;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  font-size: 18px;
  max-width: 280px;
  min-height: 65px;
  padding-top: 18px;

  @media (--viewportLarge) {
    max-width: 100%;
  }
}
