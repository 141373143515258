@import '../../styles/propertySets.css';

.root {
  display: none;
  border-top-left-radius: 55px;
  border-top-right-radius: 55px;
  background-color: var(--marketplaceColorLighter);

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 60px 80px 30px;
  }
}

.wrapper {
  @apply --backgroundImage;
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }
}
