@import '../../styles/propertySets.css';

.root {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.darkSkin {
  background-color: var(--marketplaceColorDark);
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  @apply --marketplaceLinkStyles;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  /* Font */
  @apply --marketplaceTabNavHorizontalFontStyles;
  font-size: 18px;

  color: var(--matterColor);
  padding-bottom: 10px;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    padding: 0;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  color: var(--matterColorLight);
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--marketplaceColorLight);
  margin-bottom: 2px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--matterColorLight);
  color: var(--marketplaceColor);
  background-color: var(--matterColorLight);
  height: 65px;
  width: 250px;
  margin-bottom: -30px;
  text-align: center;
  -webkit-border-top-left-radius: 28px;
  -webkit-border-top-right-radius: 28px;
  -moz-border-radius-topleft: 28px;
  -moz-border-radius-topright: 28px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
}

.selectedTabContentDarkSkin:before,
.selectedTabContentDarkSkin:after {
  content: '';
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

.selectedTabContentDarkSkin:after {
  right: -20px;
  border-radius: 0 0 0 28px;
  -moz-border-radius: 0 0 0 28px;
  -webkit-border-radius: 0 0 0 28px;
  -webkit-box-shadow: -10px 0 0 0 #fff;
  box-shadow: -10px 0 0 0 #fff;
}

.selectedTabContentDarkSkin:before {
  left: -20px;
  border-radius: 0 0 28px 0;
  -moz-border-radius: 0 0 28px 0;
  -webkit-border-radius: 0 0 28px 0;
  -webkit-box-shadow: 10px 0 0 0 #fff;
  box-shadow: 10px 0 0 0 #fff;
}

.disabledDarkSkin {
  color: var(--matterColorDark);
  opacity: 0.3;
}

.withBorders {
  & .tabContent {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    @media (--viewportMedium) {
      border-bottom-width: 3px;
      padding: 0 10px 10px;
    }
  }

  & .selectedTabContent {
    border-bottom-color: var(--matterColorDark);
    color: var(--marketplaceColorLight);
  }
}

.icon {
  & span {
    margin-left: 15px;
  }
}
