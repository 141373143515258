@import '../../styles/propertySets.css';

.root {
  background: var(--matterColorLight);
  border: 1px solid transparent;
  border-radius: 4px;

  /* Layout */
  display: flex;
  flex-direction: column;

  transition: var(--transitionStyleButton);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    &:hover {
      border-color: var(--marketplaceColorLight);
    }
  }
}

.mobile {
  display: block;
  border-radius: 8px;
  @media (--viewportMedium) {
    display: none;
  }
}

.mobile > a {
  border-radius: 8px;
}

.desktop {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.aspectRatioWrapper {
  background: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportXSmall) {
    border-radius: 8px 8px 0px 0px;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;

  @media (--viewportXSmall) {
    border-radius: 8px 8px 0px 0px;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 10px 14px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  margin-top: 6px;
}

.priceValue {
  align-self: flex-end;
  color: var(--marketplaceColorLight);

  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightBold);
  line-height: 0.9;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 23px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.distance {
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;

  & svg {
    margin-right: 6px;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: 0.01em;
  line-height: 24px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  padding: 12px 11px;

  display: flex;
  align-items: center;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorImage {
  height: 20px;
  width: 20px;
  margin-right: 8px;

  & > span {
    font-size: 9px;
  }
}

.authorName {
  font-size: 13px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 1;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.unit {
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
}

.heroButtonPrimary {
  @apply --marketplaceButtonStylesPrimary;
  padding: 4px 0px 4px 0px;
  min-height: 16px;
  font-size: 12px;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: -5px;
    width: 140px;
  }

  @media (max-width: 768px) {
    padding: 6px 0px 4px 0px;
    min-height: 36px;
    margin-bottom: -10px;
    width: 180px;
  }
}

.gratisPriceValue {
  font-size: 15px;
}

.mobileProductInfo {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;
}

.heroButtonPrimaryMobile {
  @apply --marketplaceButtonStylesPrimary;
  padding: 4px 0px 4px 0px;
  min-height: 16px;
  font-size: 12px;
  width: 32px;
  border-radius: 8px;
  /* margin-right: 12px; */
  margin-bottom: 12px;
}

.authorImageMobile {
  height: 20px;
  width: 20px;
  position: absolute;
  /* z-index: 1; */
  margin-top: 8px;
  margin-left: 8px;

  & > span {
    font-size: 9px;
  }
}
