@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* 404 page */
.wrapper {
  background-size: cover, 180px;
}

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  color: var(--matterColorLight);
  max-width: 562px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 140px;
    margin-bottom: 165px;
  }
}

.number {
  @apply --marketplaceHeroTitleFontStyles;
  text-align: center;
  color: var(--matterColorLight);
  margin: 1px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  letter-spacing: normal;
  text-align: center;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: 27px;
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  border: 0;
  border-radius: 33px;
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }

  & > div > div {
    border-top-left-radius: 33px;
    border-bottom-left-radius: 33px;
    margin-top: 0;
  }

  & > div > div:last-child {
    border-radius: 33px;
    margin-top: 16px;
  }

  & > div > input {
    border-top-right-radius: 33px;
    border-bottom-right-radius: 33px;
  }
}
