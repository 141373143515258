@import '../../../styles/propertySets.css';

.quantityField {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 16px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.buyNowButton,
.addToCartButton {
  margin-bottom: 10px;
}

.addToCartButton {
  background-color: var(--marketplaceColorLight);
  border-color: var(--marketplaceColorLight);

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
  }
}

.contactButton {
  @apply --marketplaceButtonStylesSecondaryGreen;
  @apply --marketplaceButtonStylesChevronGreen;
  padding: 0;
}

.finePrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
}

.wellPrint {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  background-color: var(--marketplaceColorMoreLight);

  font-size: 18px;
  font-weight: 600;
  text-align: left;
  line-height: 27px;
  opacity: 0.9;
  letter-spacing: 0.3px;

  width: 100%;
  margin-top: 0;
  margin-bottom: 24px;
  padding: 10px 20px;
  border-radius: 10px;
}

.wellPrintBold {
  font-weight: normal;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.cartUpdatedButtons {
  padding-top: 20px;
}

.cartUpdatedBack {
  margin-bottom: 20px;
}

.goToCart {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceButtonStylesChevronWhite;
}
