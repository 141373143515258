@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 8px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mainItems {
  margin-bottom: 42px;

  & .lineItem {
    border-bottom: 1px solid rgba(42, 211, 151, 0.15);
    padding-bottom: 10px;
    margin-bottom: 2px;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  flex: 1;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  flex: 0 0 60px;
  font-weight: var(--fontWeightRegular);
  margin: 0 0 0 10px;
  text-align: right;

  @media (--viewportMedium) {
    flex: auto;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--marketplaceColorLight);
  opacity: 0.15;

  @media (--viewportMedium) {
    margin: 18px 0 16px;
  }
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightSemiBold);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  color: var(--marketplaceColor);
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 0;
  }
}

.itemImage {
  flex: 0 0 54px;
  margin-right: 10px;

  & img {
    max-width: 100%;
  }
}

.itemInfo {
  flex: 1 1 auto;
  text-align: left;

  & p {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }

  & strong {
    font-weight: var(--fontWeightSemiBold);
    letter-spacing: normal;
  }
}
