.SideNavWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--matterColorLight);

  @media (--viewportLarge) {
    background: linear-gradient(270deg, rgba(42, 211, 151, 0.03) 0%, rgba(42, 211, 151, 0) 100%);
    padding-top: 55px;

    max-width: 327px;
  }
}

.SideNavFooter {
  margin-top: auto;

  background: var(--matterColorLight);
  height: 140px;
  border-bottom-left-radius: 25px;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}
