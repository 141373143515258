@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import './propertySets.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  --marketplaceColor: #2f4f38;
  --marketplaceColorLight: #2ad397;
  --marketplaceColorLighter: #f0fdf0;
  --marketplaceColorLightest: #f5faf5;
  --marketplaceColorMoreLight: #d6fff1;
  --marketplaceColorDark: #3e4c42;
  --marketplaceColorPale: #6b7a6f;
  --marketplaceColorGray: #929292;
  --marketplaceColorCTA: #fb31ff;
  --marketplaceColorHeroButton: #c53ac7;
  --marketplaceColorPaleYellow: #faf9eb;
  --marketplaceColorPalePeach: #f1bcc4;
  --marketplaceColorPaleGreen: #edfff9;
  --marketplaceColorSoftBlue: #eafbff;
  --marketplaceColorBrightBlue: #c6f4fe;
  --marketplaceColorBlue: #47c5e1;
  --marketplaceColorLilac: #7b7aad;
  --marketplaceColorGradient: linear-gradient(
    to bottom,
    rgba(34, 86, 49, 0.48),
    rgba(13, 57, 26, 0.28)
  );

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %232f4f38;

  --successColor: #8eba84;
  --successColorDark: #2f4f38;
  --successColorLight: #f1fffa;
  --failColor: #f2203b;
  --failColorLight: #fff1ed;
  --attentionColor: #fdfb52;
  --attentionColorLight: #fff7f0;
  --infoColor: #c6f4fe;
  --infoColorLight: #e6fafe;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #1a1a1a;
  --matterColor: #4a4a4a;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #f6f6f6;
  --matterColorLightAlt: #fdfdfd;
  --matterColorLight: #fefefe;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Font ================ */
  --fontFamily: 'poppins', Helvetica, Arial, sans-serif;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  --boxShadowTopbar: 0 0 3px 0 rgba(0, 0, 0, 0.56);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMobileSearch: 3px;
  --borderRadiusSections: 54px;
  --borderRadiusSectionsMobile: 27px;
  --borderRadiusWrapper: 27px;
  --borderRadiusWrapperAlt: 17px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeightDesktop: 72px;
  --topbarHeight: --topbarHeightDesktop;
  --topbarSearchHeight: 46px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 600px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
  --LandingPage_sectionOffset: 24px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

/* ================ Global element styles ================ */

body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  @apply --marketplaceLinkStyles;
}

h1 {
  @apply --marketplaceH1FontStyles;
}
h2 {
  @apply --marketplaceH2FontStyles;
}
h3 {
  @apply --marketplaceH3FontStyles;
}
h4 {
  @apply --marketplaceH4FontStyles;
}
h5 {
  @apply --marketplaceH5FontStyles;
}
h6 {
  @apply --marketplaceH6FontStyles;
}

li {
  @apply --marketplaceDefaultFontStyles;
}

p,
pre {
  @apply --marketplaceBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 5px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  @apply --marketplaceSelectStyles;
  @apply --marketplaceInputFontStyles;
}

input {
  @apply --marketplaceInputStyles;
  @apply --marketplaceInputFontStyles;
}

textarea {
  @apply --marketplaceInputStyles;
  @apply --marketplaceInputFontStyles;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;

  &::placeholder {
    padding: 0 0 0 0;
  }

  @media (--viewportMedium) {
    &::placeholder {
      padding: 0 0 0 0;
    }
  }
}

.fontsLoaded {
  & body {
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-feature-settings: 'ss02';
  }
  & button {
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-feature-settings: 'ss02';
  }
}

/* Override iubenda font styles */
#iubenda_policy,
#iubenda_policy.iubenda_vip_policy p,
#iubenda_policy.iubenda_vip_policy .allcaps,
#iubenda_policy.iubenda_vip_policy p.allcaps,
#iubenda_policy.iubenda_vip_policy ul.allcaps li,
#iubenda_policy h1,
#iubenda_policy h2,
#iubenda_policy h3,
#iubenda_policy h4,
#iubenda_policy h5,
#iubenda_policy h6,
#iubenda_policy p,
#iubenda_policy blockquote,
#iubenda_policy pre,
#iubenda_policy a,
#iubenda_policy abbr,
#iubenda_policy acronym,
#iubenda_policy address,
#iubenda_policy cite,
#iubenda_policy code,
#iubenda_policy del,
#iubenda_policy dfn,
#iubenda_policy em,
#iubenda_policy img,
#iubenda_policy q,
#iubenda_policy s,
#iubenda_policy samp,
#iubenda_policy small,
#iubenda_policy strike,
#iubenda_policy strong,
#iubenda_policy sub,
#iubenda_policy sup,
#iubenda_policy tt,
#iubenda_policy var,
#iubenda_policy dd,
#iubenda_policy dl,
#iubenda_policy dt,
#iubenda_policy li,
#iubenda_policy ol,
#iubenda_policy ul,
#iubenda_policy fieldset,
#iubenda_policy form,
#iubenda_policy label,
#iubenda_policy legend,
#iubenda_policy button,
#iubenda_policy table,
#iubenda_policy caption,
#iubenda_policy tbody,
#iubenda_policy tfoot,
#iubenda_policy thead,
#iubenda_policy tr,
#iubenda_policy th,
#iubenda_policy td {
  font-family: 'poppins', Helvetica, Arial, sans-serif !important;
}
