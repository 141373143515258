@import '../../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-image: linear-gradient(180deg, rgba(42, 69, 50, 0.43), rgba(42, 69, 50, 0)),
    var(--marketplaceColorGradient), url('../../../assets/crops_pattern.png');
  background-color: var(--successColor);
  background-size: cover, cover, 120px;
  background-repeat: no-repeat, no-repeat, repeat;
  background-position: top left;

  /* shadows */
  box-shadow: var(--boxShadowTopbar);

  @media (--viewportLarge) {
    padding: 0;
  }
}

/* logo */
.logoLink {
  flex-shrink: 0;
  padding: 14px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 16px 20px 12px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 44px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;

  @media (--viewportLarge) {
    margin-right: 14px;
    padding: 13px 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  @media (--viewportMedium) {
    background: var(--matterColorLight);
    height: var(--topbarSearchHeight);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (--viewportLarge) {
    background-color: var(--matterColorLight);
    top: auto;
    transform: none;
  }
}

.topbarLocationSearch {
  @media (--viewportLarge) {
    border-left: 1px solid #f2f2f2;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorLight);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  height: var(--topbarSearchHeight);
  min-height: var(--topbarSearchHeight);
  padding: 11px 28px 14px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.cartLink {
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cart {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.inbox {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &::after {
    content: '';
    background: rgba(26, 26, 26, 0.6);
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &:hover {
    border-bottom: 0;
  }

  & .avatar {
    z-index: 1;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.cartLink {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  flex-shrink: 0;
  height: calc(100% - 20px);
  padding: 0 22px 0 16px;
  margin: 10px 0 10px 4px;

  & path {
    transition: var(--transitionStyleButton);
  }

  &:hover {
    cursor: pointer;

    & path {
      fill: var(--marketplaceColorLight);
    }
  }
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

.cartIcon {
  @apply --TopbarDesktop_label;
  margin: 12px 0;
  position: relative;
}

.cartContent {
  background: var(--marketplaceColorCTA);
  border-radius: 100%;
  color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: var(--fontWeightSemiBold);
  height: 18px;
  width: 18px;
  position: absolute;
  right: -12px;
  top: 0;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: -1px;
  height: calc(100% - 2px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 38px;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 0 30px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.logoutIcon {
  margin-right: 10px;
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--marketplaceColorLight);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--marketplaceColorLight);
    }
  }
}
